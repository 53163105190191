import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import appService from "../services/app.service";
import { IReference } from "../types/cellere-basetypes";
import { Loading } from "./Loading";
import { ModalBox } from "./ModalBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "./fields/Input";
import { Auth } from "../services/auth/auth.service";
import { useSortableData } from "../services/sortableData.service";
import "./Landing.css";
import { SortButton } from "./SortButton";
import de from 'date-fns/locale/de';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import useSWR, { mutate } from "swr";
import { Select } from "./fields/Select";
import { CSVLink } from "react-csv";
import { Checkbox } from "./fields/Checkbox";

var numeral = require('numeral');
numeral.register('locale', 'de-ch', {
  delimiters: {
      thousands: "'",
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal: function (number) {
      return '.';
  },
  currency: {
      symbol: 'CHF'
  }
});

export const saveToStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const loadFromStorage = (key) => {
  const value = sessionStorage.getItem(key);
  if (value === 'undefined')
    return null;
  return value ? JSON.parse(value) : null;
};

export const clearStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const Landing: React.FunctionComponent<{ admins: string | undefined }> = (props) => {
  const [searchString, setSearchString] = useState(undefined);
  const [fromAmount, setFromAmount] = useState<number | undefined>(undefined);
  const [toAmount, setToAmount] = useState<number | undefined>(undefined);
  const [from, setFrom] = useState(undefined);
  const [selectedRegion, setSelectedRegion] = useState(sessionStorage.getItem('selectedRegion') ?? "");
  const [to, setTo] = useState(undefined);
  const [showDrafts, setShowDrafts] = useState(false);
  const [referenceToDelete, setReferenceToDelete] = React.useState<IReference>(null);

  registerLocale('de', de)
  setDefaultLocale('de');

  useEffect(() => {
    const fromDate = loadFromStorage('fromDate');
    if (fromDate) {
      setFrom(new Date(fromDate));
    }
    const toDate = loadFromStorage('toDate');
    if (toDate) {
      setTo(new Date(toDate));
    }
    const toAmount = loadFromStorage('toAmount');
    if (toAmount) {
      setToAmount(toAmount);
    }
    const fromAmount = loadFromStorage('fromAmount');
    if (fromAmount) {
      setFromAmount(fromAmount);
    }
  }, []);

  const isReadOnly = (reference: IReference, userName: string): any => {
     let readOnly = reference.createdBy?.toLocaleLowerCase() !== userName.toLocaleLowerCase() && reference.modifiedBy?.toLocaleLowerCase() !== userName.toLocaleLowerCase() && props.admins.toLocaleLowerCase().indexOf(userName.toLocaleLowerCase()) === -1

     if (reference.editors !== undefined)
       if (readOnly == true)
        readOnly = reference.editors?.toLocaleLowerCase().indexOf(userName.toLocaleLowerCase()) === -1

     return readOnly
  }

  const fetcher = async (): Promise<IReference[]> => {
    return new Promise<IReference[]>(async (resolve, reject) => {
        try {
          const references = await appService.getReferences();
          resolve(references);
        }
        catch (ex) {
          resolve([])
        }
      })};

  const referenceKey = `cellere-referenzen`;
  const { data: data, error } = useSWR<IReference[]>(referenceKey, () => fetcher());
  
  const onDeleteReference = async (): Promise<void> => {
    await appService.deleteReference(referenceToDelete.key);
    mutate(referenceKey)
    setReferenceToDelete(null);
  };

  const regionKeys = ["CSG", "CZS", "CAG", "CZH", "CGR", "CSH", "CTG", "CIB", "CTI"];

  const regionOptions = regionKeys.map((item) => {
      return {
          key: item,
          text: item,
          disabled: false,
      };
  });
  regionOptions.splice(0, 0, {
    key: "",
    text: "Alle",
    disabled: false,
});

  var account = Auth.getInstance().getUserAccount();

  const filterReferences = (reference: IReference): any => {
      let result = true;

      if (reference?.filter?.region === undefined)
        reference.filter.region = "CSG"

      result = showDrafts === reference.isDraft;
      if (result === false)
        return
      
      if (searchString !== undefined) {
          const searches = searchString.toLowerCase().split(" ");
          var i;
          for (i = 0; i < searches.length; i++) {
            let lowerCaseString = searches[i];
            if (!result) {
              continue;
            }

            result = reference.title?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.location?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.description?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.executionAs1?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.executionAs2?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.polisher1?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.polisher2?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.siteManager1?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.siteManager2?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.projectManager?.toLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.locationZip?.toLocaleLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.picture2Legend?.toLocaleLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.picture1Legend?.toLocaleLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.filter?.builderType?.toLocaleLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.filter?.location?.toLocaleLowerCase().indexOf(lowerCaseString) > -1 ||
            reference.filter?.region?.toLocaleLowerCase().indexOf(lowerCaseString) > -1;

            if (reference.services !== undefined && result === false) {
                result = reference.services.some((item) => item?.toLowerCase().indexOf(lowerCaseString) > -1);
            }

            if (reference.reasonWhy !== undefined && result === false) {
                result = reference.reasonWhy.some((item) => item?.toLowerCase().indexOf(lowerCaseString) > -1);
            }

            if (reference.challenges !== undefined && result === false) {
                result = reference.challenges.some((item) => item?.toLowerCase().indexOf(lowerCaseString) > -1);
            }

            if (reference.projectNumbers !== undefined && result === false) {
                result = reference.projectNumbers.some((item) => item?.description?.toLowerCase().indexOf(lowerCaseString) > -1);
            }

            if (reference.client !== undefined && result === false) {
                result = reference.client?.city?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.client?.fullName?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.client?.mail?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.client?.zip?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.client?.phone?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.client?.street?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.client?.text?.toLowerCase().indexOf(lowerCaseString) > -1;
            }

            if (reference.representative !== undefined && result === false) {
                result = reference.representative?.city?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.representative?.fullName?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.representative?.mail?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.representative?.zip?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.representative?.phone?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.representative?.street?.toLowerCase().indexOf(lowerCaseString) > -1 ||
                    reference.representative?.text?.toLowerCase().indexOf(lowerCaseString) > -1;
            }
          }
      }

      if (selectedRegion !== undefined && selectedRegion !== "" && result === true) {
         result = reference.filter?.region?.toLocaleLowerCase() === selectedRegion.toLocaleLowerCase();
      }

      if (((from !== undefined && from !== null) || (to !== undefined && to !== null)) && result === true && reference.constructionTimeFrom !== undefined && reference.constructionTimeTo !== undefined) {
          var dateFrom = new Date(reference.constructionTimeFrom);
          var dateTo = new Date(reference.constructionTimeTo)

          if ((from !== undefined && from !== null) && (to !== undefined && to !== null)) {
              result = from <= dateFrom && dateTo <= to;
          } else if ((to !== undefined && to !== null)) {
              result = dateTo <= to
          } else if (from !== undefined && from !== null) {
              result = from <= dateFrom
          }
      }

      if (fromAmount !== undefined && fromAmount !== null && result === true) {
          result = reference?.totalCost >= fromAmount;
      }

      if (toAmount !== undefined && toAmount !== null && result === true) {
          result = reference?.totalCost <= toAmount;
      }

      if (result) {
          return reference;
      }
  };

  const getCurrency = (number: string | undefined) => {
    if (number === undefined) {
      return "";
    }

    var currencyNumber = numeral(number);
    numeral.defaultFormat('0,0 $');
    numeral.locale('de-ch');
    return currencyNumber.format();
  }

  const references: IReference[] = !data ? [] : data.filter(filterReferences);
  const { items, requestSort, sortConfig } = useSortableData(references);

  const csvReport = {
    data: references?.map(function (p) { 
      return {
        "title": p.title,
        "totalCost": p.totalCost,
        "constructionTimeFrom": p.constructionTimeFrom,
        "constructionTimeTo": p.constructionTimeTo,
        "cFullName": p.client?.fullName,
        "cAddition": p.client?.text,
        "cStreet": p.client?.street,
        "cCity": p.client?.city,
        "cZip": p.client?.zip,
        "cPhone": p.client?.phone,
        "cMail": p.client?.mail,
        "rFullName": p.representative?.fullName,
        "rAddition": p.representative?.text,
        "rStreet": p.representative?.street,
        "rCity": p.representative?.city,
        "rZip": p.representative?.zip,
        "rPhone": p.representative?.phone,
        "rMail": p.representative?.mail,
        "siteManager1": p.siteManager1,
        "siteManager2": p.siteManager2,
        "polisher1": p.polisher1,
        "polisher2": p.polisher2,
        "projectManager": p.projectManager,
        "location": p.location,
        "region": p.filter?.region ?? "CSG",
        "locationZip": p.locationZip
    }}),
    headers: [
      { label: "Baustelle", key: "title" },
      { label: "Bausumme", key: "totalCost" },
      { label: "Anfang", key: "constructionTimeFrom" },
      { label: "Ende", key: "constructionTimeTo" },
      { label: "Ort", key: "location" },
      { label: "Postleitzahl", key: "locationZip" },
      { label: "Region", key: "region" },
      { label: "Bauherr", key: "cFullName" },
      { label: "Bauherr-Zusatz", key: "cAddition" },
      { label: "Bauherr-Straße", key: "cStreet" },
      { label: "Bauherr-Ort", key: "cCity" },
      { label: "Bauherr-PostleitZahl", key: "cZip" },
      { label: "Bauherr-Telefon", key: "cPhone" },
      { label: "Bauherr-Mail", key: "cMail" },
      { label: "Bauherrenvertretung", key: "rFullName" },
      { label: "Bauherrenvertretung-Zusatz", key: "rAddition" },
      { label: "Bauherrenvertretung-Straße", key: "rStreet" },
      { label: "Bauherrenvertretung-Ort", key: "rCity" },
      { label: "Bauherrenvertretung-PostleitZahl", key: "rZip" },
      { label: "Bauherrenvertretung-Telefon", key: "rPhone" },
      { label: "Bauherrenvertretung-Mail", key: "rMail" },
      { label: "Projektleiter", key: "projectManager" },
      { label: "Bauführer", key: "siteManager1" },
      { label: "Weiterer Bauführer", key: "siteManager2" },
      { label: "Polier", key: "polisher1" },
      { label: "Weiterer Polier", key: "polisher2" }
    ],
    filename: 'Referenzen.csv'
  };

  return (
    <React.Fragment>
      <Loading isLoading={!data}></Loading>

      {data && (
        <div className="section">
          <ModalBox
            title="Soll ich diesen Eintrag wirklich löschen"
            isActive={referenceToDelete !== null}
            onCloseClick={() => setReferenceToDelete(null)}
            onConfirmClick={() => onDeleteReference()}
          >
            Soll dieser Eintrag wirklich gelöscht werden?
          </ModalBox>

            <div className="container is-widescreen is-fluid infoDetails">
                <div className="columns">
                  <div className="column">
                  <label className="label">Suche</label>
              <div className="control">
                <Input
                  placeholder="Text eingeben"
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                ></Input>
              </div>
                  </div>
                </div>
              
                <div className="columns">
                  <div className="column">
                    <label className="label">Bauzeit von</label>
                    <div className="control">
                      <DatePicker
                        className="input"
                        selected={from}
                        dateFormat="dd.MM.yyyy"
                        onChange={(e) => {
                          setFrom(e);
                          saveToStorage("fromDate", e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="column">
                    <label className="label">Bauzeit bis</label>
                      <div className="control">
                        <DatePicker
                          className="input"
                          selected={to}
                          dateFormat="dd.MM.yyyy"
                          onChange={(e) => {
                            setTo(e);
                            saveToStorage("toDate", e)
                          }}
                        />
                      </div>
                  </div>
                
                  <div className="column">
                    <label className="label">Bausumme von</label>
                    <div className="control">
                      <Input
                        value={fromAmount}
                        onChange={(e) => {
                          try {
                            const value = parseInt(e.target.value);
                            if (isNaN(value)) {
                              setFromAmount(undefined);
                              saveToStorage("fromAmount", undefined)
                            } else {
                              setFromAmount(value);
                              saveToStorage("fromAmount", value)
                            }
                          } catch {}
                        }}
                      ></Input>
                    </div>
                  </div>
                  <div className="column">
                  <label className="label">Bausumme bis</label>
                    <div className="control">
                      <Input
                        value={toAmount}
                        onChange={(e) => {
                          try {
                            const value = parseInt(e.target.value);
                            if (isNaN(value)) {
                              setToAmount(undefined);
                              saveToStorage("toAmount", undefined)
                            } else {
                              setToAmount(value);
                              saveToStorage("toAmount", value)
                            }
                          } catch {}
                        }}
                      ></Input>
                    </div>
                  </div>
                  <div className="column">
                    <label className="label">Region</label>
                    <div className="control">
                      <Select
                        className="input"
                        value={selectedRegion}
                        options={regionOptions}
                        onChange={(e) => {
                          sessionStorage.setItem('selectedRegion', e.target.value);
                          setSelectedRegion(e.target.value)
                        }}
                      />
                    </div>
                  </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <CSVLink className="button is-primary is-small is-pulled-right" {...csvReport}>Export</CSVLink>
              <div className="is-pulled-right">&nbsp;&nbsp;</div>
              <Link
                className="button is-primary is-small is-pulled-right"
                to="/details"
              >
                Neue Referenz erfassen
              </Link>
            </div>
          </div>

          <div className="table-container">
            <table className="table is-fullwidth">
              <thead>
                              <tr>

                                  <th>
                                      <Checkbox onChange={() => setShowDrafts(!showDrafts)} checked={showDrafts} label=" Entwürfe"></Checkbox>
                                  </th>
                  <th>
                    <SortButton
                      property="title"
                      title="Baustelle"
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                    ></SortButton>
                  </th>
                  <th>
                    <SortButton
                      property="totalCost"
                      title="Bausumme"
                      typeName="Number"
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                    ></SortButton>
                  </th>
                  <th>
                    <SortButton
                      property="constructionTimeFrom"
                      title="Anfang"
                      typeName="Date"
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                    ></SortButton>
                  </th>
                  <th>
                    <SortButton
                      property="constructionTimeTo"
                      title="Ende"
                      typeName="Date"
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                    ></SortButton>
                  </th>
                  <th>
                    <SortButton
                      property="client.text"
                      title="Bauherr"
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                    ></SortButton>
                  </th>
                  <th>
                    <SortButton
                      property="locationZip"
                      title="Postleitzahl"
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                    ></SortButton>
                  </th>
                  <th>
                    <SortButton
                      property="location"
                      title="Ort"
                      sortConfig={sortConfig}
                      requestSort={requestSort}
                    ></SortButton>
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((reference) => (
                    <tr key={reference.key}>
                        <td className="actions">
                            {reference.isDraft === false && (
                                <Link
                                    className="button is-primary is-small"
                                    to={`/print/${reference.key}`}
                                >
                                    <span className="icon is-small">
                                        <i className={`fa fa-print`}></i>
                                    </span>
                                </Link>
                            )}
                            <Link
                                className="button is-primary is-small"
                                to={`/details/${reference.key}`}
                            >
                                <span className="icon is-small">
                                    <i
                                        className={`fas ${isReadOnly(reference, account.username)
                                                ? "fa-folder-open"
                                                : "fa-edit"
                                            }`}
                                    ></i>
                                </span>
                            </Link>
                            <button
                                className="button is-link is-danger is-small"
                                title="Löschen"
                                disabled={isReadOnly(reference, account.username)}
                                onClick={() => {
                                    setReferenceToDelete(reference);
                                }}
                            >
                                <span className="icon is-small">
                                    <i className={`fas fa-trash`}></i>
                                </span>
                            </button>
                        </td>
                    <td>{reference.title}</td>
                    <td className="has-text-right">{getCurrency(reference.totalCost)}</td>
                    <td>
                      {moment(reference.constructionTimeFrom).format(
                        "DD.MM.yyyy"
                      )}
                    </td>
                    <td>
                      {moment(reference.constructionTimeTo).format(
                        "DD.MM.yyyy"
                      )}
                    </td>
                    <td>{reference.client?.text}</td>
                    <td>{reference.locationZip}</td>
                    <td>{reference.location}</td>
                  </tr>
                ))}
                {items.length === 0 && (
                  <tr>
                    <td colSpan={6}>Keine Einträge gefunden</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
